<template>
    <div>
        <heads :more="false" msg="保障协议"></heads>
        <van-loading v-show="showLoading" class="loading"/>
        <div class="div_page" v-show="!showLoading">
            <p class="p_title">{{form.q_agreement_name}}</p>
            <div class="div_input">
                <div class="div_jia">
                    <van-cell-group>
                        <van-field v-model="form.q_company_a_party" label="甲方： " placeholder="请输入姓名" />
                        <van-field v-model="form.q_company_a_card" label="身份证号：" placeholder="请输入身份证号" />
                        <van-field v-model="form.q_company_a_phone" label="联系电话：" placeholder="请输入联系电话" />
                        <van-field v-model="form.q_company_a_zl" label="筑龙用户名：" :disabled = "true"/>
                        <van-field v-model="form.q_company_a_order" label="订单号：" :disabled = "true"/>
                    </van-cell-group>
                </div>
                <div class="div_yi">
                    <van-cell-group>
                        <van-field v-model="form.q_company_b_party" label="乙方：" placeholder="请输入姓名" :disabled = "true"/>
                        <van-field v-model="form.q_company_b_website" label="网址：" placeholder="请输入身份证号" :disabled = "true"/>
                        <van-field v-model="form.q_company_b_address" label="地址：" placeholder="请输入联系电话" :disabled = "true"/>
                        <van-field v-model="form.q_company_b_phone" label="联系电话：" :disabled = "true"/>
                        <van-field v-model="form.q_company_b_email" label="邮箱：" :disabled = "true"/>
                    </van-cell-group>
                </div>
            </div>
            <p v-html="q_agreement_content"></p>
            <div class="content_btm">
                <div class="btn_left">
                    <div class="partyA">
                        <span class="inpLeft leftCenter">甲方</span>
                        <span>：</span>
                        <input v-model="form.q_company_a_party" type="text" class="inp"></input>
                    </div>
                    <!-- <div class="partyA">
                      <span class="inpLeft leftCenter"></span>
                      <span></span>
                    </div> -->
                    <div class="partyA">
                        <span class="inpLeft leftCenter">联系人</span>
                        <span>：</span>
                        <input v-model="form.q_company_a_contacts" type="text" class="inp"></input>
                    </div>
                    <div class="partyA">
                        <span class="inpLeft leftCenter">联系电话</span>
                        <span>：</span>
                        <input v-model="form.q_company_a_phone" type="text" class="inp"></input>
                    </div>
                    <div class="partyA">
                        <span class="inpLeft leftCenter">日期</span>
                        <span>：</span>
                        <span class="aline">
              <span class="shortInpB">{{ btmDateA.year }}</span>
              <span>年</span>
              <span class="shortInpB">{{ btmDateA.month }}</span>
              <span>月</span>
              <span class="shortInpB">{{ btmDateA.date }}</span>
              <span>日</span>
            </span>
                    </div>
                </div>
                <div class="btn_right">
                    <div class="partyA">
                        <span class="inpLeft leftCenter">乙方</span>
                        <span>：</span>
                        <span class="blod">{{ form.q_company_b_party }}</span>
                    </div>
                    <div class="partyA">
                        <span class="inpLeft leftCenter">盖章</span>
                        <span>：</span>
                        <span class="btmInpSpan"></span>
                    </div>
                    <div class="partyA">
                        <span class="inpLeft leftCenter">联系人</span>
                        <span>：</span>
                        <span class="btmInpSpan">{{ form.q_company_b_contacts }}</span>
                    </div>
                    <div class="partyA">
                        <span class="inpLeft leftCenter">联系电话</span>
                        <span>：</span>
                        <span class="btmInpSpan">{{ form.q_company_b_phone }}</span>
                    </div>
                    <div class="partyA">
                        <span class="inpLeft leftCenter">日期</span>
                        <span>：</span>
                        <span class="aline">
              <span class="shortInpB">{{ btmDateB.year }}</span>
              <span>年</span>
              <span class="shortInpB">{{ btmDateB.month }}</span>
              <span>月</span>
              <span class="shortInpB">{{ btmDateB.date }}</span>
              <span>日</span>
            </span>
                    </div>
                    <!-- v-if="form.q_company_b_sign"  -->
                    <div class="company_b_sign">
                        <img :src="form.q_company_b_sign" alt="">
                    </div>
                </div>
            </div>
            <van-button  v-show="form.status===0" type="danger" class="button" @click="save">确认</van-button>
        </div>

    </div>
</template>

<script>
    import heads from '@/compontens/heads.vue'
    import { getAgreementInfo, confirmAgreement } from '@/api/my_user.js'
    export default {
        components: { heads },
        data () {
            return {
                showLoading:true,
                form: {
                    order_id: '',
                    order_sn: '',
                    lesson_id: '',
                    agreement_id: '',
                    q_agreement_name: '',
                    q_company_a_party: '', // 甲方名称
                    q_company_a_card: '', // 甲方身份证号
                    q_company_a_phone: '', // 甲方手机号
                    q_company_a_contacts: '', // 甲方联系人
                    q_company_a_zl: '', // 甲方筑龙用户名
                    q_company_a_order: '', // 甲方订单号
                    q_company_b_party: '', // 乙方名称
                    q_company_b_website: '', // 乙方网址
                    q_company_b_address: '', // 乙方地址
                    q_company_b_phone: '', // 乙方联系电话
                    q_company_b_contacts: '', // 乙方联系人
                    q_company_b_email: '', // 乙方邮箱
                    q_company_b_sign: '', // 乙方印章
                    status: 0
                },
                q_agreement_content: '', // 协议内容
                btmDateA: {
                    year: '',
                    month: '',
                    date: ''
                },
                btmDateB: {
                    year: '',
                    month: '',
                    date: ''
                }
            }
        },
        mounted() {
            this.agreementInfo()
        },
        methods:{
            agreementInfo() {
                var str = this.$route.query.listQuery
                var obj = JSON.parse(str)
                getAgreementInfo(obj).then(res => {
                    this.showLoading = false
                    const result = res.result
                    this.q_agreement_content = result.q_agreement_content
                    this.form = result
                    if (this.form.q_agreement_content) {
                        this.form.q_agreement_content = ''
                    }
                    this.btmDateA.year = result.q_company_a_time.slice(0, 4)
                    this.btmDateA.month = result.q_company_a_time.slice(5, 7)
                    this.btmDateA.date = result.q_company_a_time.slice(8, 10)
                    this.btmDateB.year = result.q_company_b_time.slice(0, 4)
                    this.btmDateB.month = result.q_company_b_time.slice(5, 7)
                    this.btmDateB.date = result.q_company_b_time.slice(8, 10)
                })
            },
            save() {
                confirmAgreement(this.form).then(res => {
                    if (res.errNo === 0) {
                        const msg = res.result ? res.result : '保存成功'
                        this.$message.success(msg)
                        this.$router.go(-1)
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .loading{
        display: block;
        width: 200px;
        text-align: center;
        padding-top: 20px;
        margin: 0 auto;
    }
    .div_page{
        padding: 32px 40px;
        .p_title{
            text-align: center;
            font-size:42px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 500;
        }
        .div_input{
            margin: 40px 0px;
            .div_yi{
                margin-top: 40px;
            }
            .div_jia{
                /deep/.van-cell:first-child {
                    .van-cell__title{
                        margin-right: 47px;
                    }
                }
            }
            /deep/.van-hairline--top-bottom::after,van-hairline--top-bottom::after{
                border: none !important;
            }
            /deep/.van-field__label {
                width: auto;
                font-size: 24px;
                color: #333333;
                margin-right: 0px;
            }
            /deep/.van-cell {
                padding: 12px 16px 12px 0px;
            }
            /deep/.van-cell::after{
                border: none;
            }
            /deep/.van-field__body {
                .van-field__control {
                    color: #333333;
                    -webkit-text-fill-color: #333333;
                    border: 1px solid #D8D8D8;
                    padding-left: 12px;
                    border-radius: 4px;
                }
                .van-field__control:disabled {
                    color: #333333;
                    -webkit-text-fill-color: #333333;
                    border: none;
                    padding-left: 0px;
                }
            }
        }
        .content_btm{
            margin: 40px 0 0 0;
            display: flex;
            flex-direction: column;
            .btn_left{
                flex-shrink: 0;
                position: relative;
                padding: 20px 0;
                span{
                    font-size: 14px;
                }
            }
            .partyA{
                padding: 5px 0 5px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .inpLeft{
                display: inline-block;
                // width: 90px;
                font-weight: bold;
                font-size: 14px;
                height: 30px;
                line-height: 30px;
            }
            .leftCenter{
                width: 100px;
                text-align: justify;
                text-align-last: justify;
            }
            .shortInpB{
                display: inline-block;
                width: 56px;
                border-bottom: 1px solid #bbbbbb;
                height: 20px;
                line-height: 20px;
            }
            .btn_right{
                position: relative;
                height: 250px;
                margin-top: 20px;
                padding: 10px 0;
                span{
                    font-size: 14px;
                }
                .company_b_sign {
                    position: absolute;
                    left: 0;
                    margin-left: 100px;
                    bottom: 50px;
                    width: 200px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .button{
            display: block;
            width: 320px;
            margin: 30px auto 0 auto;
        }
        .inp{
            margin-left: 6px;
            width: 210px;
            padding-bottom: 8px;
            border-bottom: 1px solid #bbbbbb;
            height: 20px;
        }
    }


</style>
